import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  emailRegex,
  phoneRegex,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TravelWalletSingleSelectionStep,
} from "halifax";
import {
  ADD_CONTACT_INFO,
  CarsBookErrorEnum,
  ITravelerStepErrors,
  PaymentError,
} from "redmond";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import clsx from "clsx";
import { MobileCarBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  CarBookPassengerSelection,
  PaymentCard,
  CarBookMobileButton,
  CarBookSummaryPanel,
  PriceBreakdownDropdown,
  AGENT_FEE,
} from "../../index";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  REVIEW_MY_TRIP_TEXT,
  PRICE_QUOTE_MESSAGE,
  TITLE,
  SUBTITLE,
  UNDER_25_WARNING,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  UPDATE_DRIVER,
} from "./textConstants";
import { PATH_SHOP, PATH_BOOK_CONFIRMATION } from "../../../../../utils/paths";
import { ClientContext } from "../../../../../App";
import { CarShopSmallMap } from "../../../../shop/components/CarShopSmallMap/component";
import { trackEvent } from "../../../../../api/v1/analytics/trackEvent";
import { fetchCustomerDetails } from "../../../../../api/v1/customer/fetchCustomerDetails";
import {
  AVAILABLE,
  CARS_CX_V1,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../../context/experiments";
import {
  HertzLoyaltyStep,
  HertzLoyaltyWorkflow,
} from "../../MobileHertzLoyaltyWorkflow";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { ProductError } from "@b2bportal/purchase-api";

export interface IMobileCarBookWorkflowProps
  extends RouteComponentProps,
    MobileCarBookWorkflowConnectorProps {}

enum MobileCarBookWorkflowStep {
  TravelerInformation,
  ContactInformation,
  HertzRewards,
  TravelOfferSelection,
  RewardsAndPayment,
  Review,
}

export const MobileCarBookWorkflow = ({
  priceQuoteInProgress,
  hasNoUserPassengers,
  schedulePriceQuote,
  history,
  reservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleBook,
  selectedVehicle,
  driverAge,
  canBook,
  isHertzProvider,
  priceQuoteErrors,
  confirmationDetailsErrors,
  hertzLoyaltyRewardsNumber,
  offers,
  fetchApplicableTravelWalletItems,
  errorTitles,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  priceQuote,
  credit,
  isTravelWalletPaymentOnly,
  creditToApply,
  fetchTravelWalletCreditHistory,
  useV1PurchaseFlow,
}: IMobileCarBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [checkoutStep, setCheckoutStep] =
    useState<MobileCarBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [hertzLoyaltyStep, setHertzLoyaltyStep] = useState<HertzLoyaltyStep>(
    HertzLoyaltyStep.Main
  );
  const [travelWalletSelectStep, setTravelWalletSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    loyaltyNumber: false,
  });
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const onBookCar = () => {
    priceDifferenceAcknowledged || canBook
      ? scheduleBook(isAgentPortal ? AGENT_FEE : 0)
      : schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0);
  };

  const openCarBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const openHertzRewards = () =>
    setHertzLoyaltyStep(HertzLoyaltyStep.HertzLoyaltyForm);

  const openTravelWalletSelection = () => {
    setTravelWalletSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };
  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      if (step === MobileCarBookWorkflowStep.ContactInformation) {
        if (!isHertzProvider) {
          if (
            isCreditAndOfferStackingExperimentV1 ||
            ((isTravelWalletCreditsExperiment ? !credit : true) &&
              (isTravelWalletOfferExperiment ? !offers?.length : true))
          ) {
            return step + 3;
          } else {
            return step + 2;
          }
        }
      }
      if (step === MobileCarBookWorkflowStep.HertzRewards) {
        if (
          isCreditAndOfferStackingExperimentV1 ||
          ((isTravelWalletCreditsExperiment ? !credit : true) &&
            (isTravelWalletOfferExperiment ? !offers?.length : true))
        ) {
          return step + 2;
        }
      }
      if (step === MobileCarBookWorkflowStep.TravelOfferSelection) {
        if (isTravelWalletPaymentOnly) {
          return step + 2;
        }
      }
      return step === MobileCarBookWorkflowStep.Review ? step : step + 1;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileCarBookWorkflowStep.TravelerInformation:
        history.push(PATH_SHOP);
        break;
      case MobileCarBookWorkflowStep.RewardsAndPayment:
        if (
          (isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !credit
            : true) &&
          (isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !offers?.length
            : true)
        ) {
          if (!isHertzProvider) {
            setCheckoutStep((step) => step - 3);
          } else {
            setCheckoutStep((step) => step - 2);
          }
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      case MobileCarBookWorkflowStep.TravelOfferSelection:
        if (!isHertzProvider) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setHertzLoyaltyStep(HertzLoyaltyStep.Main);
    setTravelWalletSelectStep(TravelWalletSingleSelectionStep.Main);
    setOpenPaymentCard(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    if (isHertzProvider) {
      setCheckoutStep(MobileCarBookWorkflowStep.HertzRewards);
    } else {
      schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, true);
      resetPaymentCardSelectedAccounts();
      setUpdatedInfoOnReview(true);
    }
  };

  React.useEffect(() => {
    if (
      [...priceQuoteErrors, ...confirmationDetailsErrors].some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError)?.value?.value?.msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber
          : (error as PaymentError)?.msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber;
      })
    ) {
      setCheckoutStep(MobileCarBookWorkflowStep.HertzRewards);
    }

    if (
      [...priceQuoteErrors, ...confirmationDetailsErrors].some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError)?.value?.value?.msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch
          : (error as PaymentError)?.msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch;
      }) ||
      errorTitles.primaryButtonText === UPDATE_DRIVER
    ) {
      setCheckoutStep(MobileCarBookWorkflowStep.TravelerInformation);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    switch (checkoutStep) {
      case MobileCarBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openCarBookPassengerSelection();
        break;
      case MobileCarBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileCarBookWorkflowStep.HertzRewards:
        closeAllPopovers();
        openHertzRewards();
        break;
      case MobileCarBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelWalletSelection();
        break;
      case MobileCarBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === MobileCarBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobileCarBookWorkflowStep.Review &&
      !priceQuoteInProgress &&
      !!priceQuote &&
      updatedInfoOnReview
    ) {
      if (creditToApply) {
        setCheckoutStep(MobileCarBookWorkflowStep.TravelOfferSelection); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
      setUpdatedInfoOnReview(false);
    }
  }, [checkoutStep, priceQuoteInProgress, priceQuote, updatedInfoOnReview]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote(null);
    }
  }, [priceQuoteInProgress]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [checkoutStep]);

  const isUnderAge = (driverAge || 0) < 25;

  return (
    <>
      <Box
        className={clsx("mobile-car-book-workflow-root", {
          "mobile-review-car-book":
            checkoutStep === MobileCarBookWorkflowStep.Review,
        })}
      >
        {/* TODO: Header should have pricing details */}
        <Header
          className="mobile-car-book-header checkout"
          left={
            <BackButton
              className="mobile-car-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            <PriceBreakdownDropdown
              popoverClassName={
                checkoutStep === MobileCarBookWorkflowStep.Review
                  ? "mobile-review-car-book-price-breakdown"
                  : undefined
              }
            />
          }
          isMobile
          fullWidth
        />
        <Box className="title-summary-container">
          <Typography variant="h6" className="title">
            {TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {SUBTITLE}
          </Typography>
        </Box>
        <CarBookSummaryPanel
          isMobile
          hidePriceAndReward
          isCarsCXV1Experiment={isCarsCXV1Experiment}
        />
        <Box className="car-shop-map-container">
          {selectedVehicle && (
            <CarShopSmallMap selectedVehicle={selectedVehicle} isMobile />
          )}
        </Box>
        <CarBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileCarBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileCarBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileCarBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onTravelersStep={
            checkoutStep === MobileCarBookWorkflowStep.TravelerInformation
          }
          isMobile
          useLocalId={true}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileCarBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile
          contactInfo={contactInfo}
          onGoBack={() => {
            if (checkoutStep === MobileCarBookWorkflowStep.ContactInformation) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfo) => {
            setContact(contactInfo);
            setContactInfo(contactInfo.email, contactInfo.phoneNumber);
            if (
              contactInfo.email &&
              contactInfo.phoneNumber &&
              emailRegex.test(contactInfo.email) &&
              phoneRegex.test(contactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobileCarBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                isHertzProvider
                  ? setHertzLoyaltyStep(HertzLoyaltyStep.HertzLoyaltyForm)
                  : schedulePriceQuote(
                      history,
                      isAgentPortal ? AGENT_FEE : 0,
                      true
                    );
                break;
            }
          }}
          loading={customerDetailsLoading}
        />
        {isHertzProvider && (
          <HertzLoyaltyWorkflow
            progressStep={hertzLoyaltyStep}
            setProgressStep={setHertzLoyaltyStep}
            onGoBack={() => {
              if (checkoutStep === MobileCarBookWorkflowStep.HertzRewards) {
                handleGoBack();
              }
            }}
            onContinueClick={() => {
              schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, true);
              incrementCheckoutStep();
            }}
            mobileHeaderElement={<PriceBreakdownDropdown />}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            hertzLoyaltyRewardsNumber={hertzLoyaltyRewardsNumber}
          />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelWalletSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileCarBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelWalletSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <PriceBreakdown />
        <Box
          className={clsx("underage-banner-container", {
            "less-padding": isTreesModalExperiment,
          })}
        >
          {isUnderAge && (
            <NotificationBanner
              className="underage-banner"
              label={UNDER_25_WARNING}
              severity={BannerSeverity.INFO}
              icon={
                <Icon className="banner-icon" name={IconName.InfoCircleFill} />
              }
            />
          )}
        </Box>
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        <CarBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => onBookCar()}
          // TODO: simplify/trim CarBookMobileButton logic
          showPaymentStep
          onApplyRewards={() => {}}
          paymentCardOpen={openPaymentCard}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (checkoutStep === MobileCarBookWorkflowStep.RewardsAndPayment) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<PriceBreakdownDropdown />}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("car-payment-card-popup", "b2b")}
      fullScreen
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="car-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className={clsx("car-book-review-trip-button", "b2b")}
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {REVIEW_MY_TRIP_TEXT}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
